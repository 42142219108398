<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <div
      app
      class="elevation-0 d-block menu"
    >
      <v-btn
        color="RMblack"
        class="pa-0"
        min-height="48"
        min-width="48"
        :rounded="true"
        @click="openSideBarMenu()"
      >
        <v-icon
          color="white"
          size="24"
        >
          mdi-menu
        </v-icon>
      </v-btn>
    </div>

    <!-- Time Window Buttons -->
    <div
      v-if="app_user.listAppUserEnabledServicesReady"
      class="d-flex justify-center containerTimeWindow"
    >
      <!-- Sezione Alert Ex-Post -->
      <div
        v-if="$route.path.includes('alert-ex-post')"
        class="d-flex timeWindowButtons justify-space-between"
      >
        <v-btn
          dark
          icon
          text
          class="button"
          width="auto"
          @click="sheetAlertExPost = true"
        >
          {{ $t(`NavigationSideBar.alertExPost`) }}
        </v-btn>
      </div>

      <!-- Sezione Servizi Temporali normali -->
      <div
        v-else
        class="d-flex timeWindowButtons justify-space-between"
      >
        <div
          v-for="item in itemsServices"
          :key="item.title"
        >
          <v-btn
            v-if="app_user.listAppUserEnabledServices.some(i => {
              if (i.service_type === 'WS') {
                return i.service_ws_group.some(it => it === item.label);
              }
            })"
            dark
            icon
            text
            class="button"
            :class="$route.params.id === item.label ? 'active-item' : 'not-active-item'"
            @click="canBeOpened = true; openDynamicNavigationDrawerWorkspace(item.label, `NavigationDrawer.${item.title}`)"
          >
            <svg-icon
              color1="#000000"
              color2="#FFFFFF"
              :path="item.svgPath"
            />
            <span
              v-if="$route.params.id === item.label"
              class="ml-1 buttonText"
            >
              {{ $t('NavigationDrawer.'+item.title) }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>

    <v-navigation-drawer
      id="menuSideBar"
      v-model="menuSideBar"
      right
      absolute
      temporary
      dark
      width="75%"
    >
      <div
        class="d-flex flex-column-reverse"
        style="background-color: #505050; height: 100%;"
      >
        <div class="d-flex justify-space-between topBarMenu">
          <b>Menu</b>
          <v-icon
            color="white"
            size="20"
            @click="openSideBarMenu()"
          >
            mdi-arrow-collapse-right
          </v-icon>
        </div>
        <div
          style="background-color: #505050; overflow-y: auto; height: 100%;"
        >
          <div
            class="d-flex flex-column-reverse justify-end"
            style="height: inherit;"
          >
            <!-- Primo blocco -->
            <div>
              <v-list style="background-color: unset">
                <!-- Supporto Op e Servizi Agg -->
                <div
                  v-for="item in itemsMoreServices"
                  :key="item.title"
                >
                  <v-list-item
                    v-if="app_user.listAppUserEnabledServices.some(i =>
                      i.service_type === 'URL' || i.service_type === 'DOC' || i.service_type === 'ALERT EX-POST' || i.service_type === 'REPORTS' || i.service_type === 'REPORT-HUB'
                    )"
                    link
                    @click="openSubMenuSideBar(item.navigationPath, item.service_type)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(`NavigationDrawer.${item.title}`) }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <SvgIcon
                        :color1="'#000000'"
                        :color2="'#FFFFFF'"
                        :title="$t(`NavigationDrawer.${item.title}`) "
                        :path="item.svgPath"
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
                <!-- Se si è in alert-ex-post -->
                <div
                  v-if="$route.path.includes('alert-ex-post')"
                  class="d-flex flex-column-reverse"
                >
                  <v-list-item-group
                    v-for="item in itemsServices"
                    :key="item.title"
                  >
                    <v-list-item
                      v-if="app_user.listAppUserEnabledServices.some(i => {
                        if (i.service_type === 'WS') {
                          return i.service_ws_group.some(it => it === item.label);
                        }
                      })"
                      link
                      @click="canBeOpened = true; openDynamicNavigationDrawerWorkspace(item.label, `NavigationDrawer.${item.title}`)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t(`NavigationDrawer.${item.title}`) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <SvgIcon
                          :color1="'#000000'"
                          :color2="'#FFFFFF'"
                          :title="$t(`NavigationDrawer.${item.title}`) "
                          :path="item.svgPath"
                        />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-item-group>
                  <v-divider color="#FFFFFF" />
                </div>
              </v-list>
            </div>
            <div class="divider" />
            <!-- Impostazioni -->
            <div class="d-flex flex-column-reverse">
              <!-- Settings e Area Utente -->
              <v-list
                style="background-color: unset"
              >
                <v-list-item
                  v-for="item in itemsSettingsAndSupport"
                  :key="item.title"
                  link
                  @click="openSubMenuSideBar(item.navigationPath, item.service_type)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(`NavigationDrawer.${item.title}`) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <SvgIcon
                      :color1="'#000000'"
                      :color2="'#FFFFFF'"
                      :title="$t(`NavigationDrawer.${item.title}`) "
                      :path="item.svgPath"
                    />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-divider color="#FFFFFF" />
              <!-- Logout -->
              <v-list style="background-color: unset; padding: 0px">
                <v-list-item @click="signOut">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('NavigationDrawer.logOut') }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <SvgIcon
                      :color1="'#000000'"
                      :color2="'#FFFFFF'"
                      :title="$t('NavigationDrawer.logOut')"
                      :path="svgLogoutIcon"
                    />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      id="subMenuSideBar"
      v-model="subMenuSideBar"
      right
      absolute
      temporary
      dark
      width="75%"
    >
      <div
        class="d-flex flex-column-reverse"
        style="background-color: #505050; height: 100%;"
      >
        <div
          class="d-flex justify-space-between topBarMenu"
        >
          <span style="color: white;">
            <b>{{ $t(drawer.drawerTitle) }}</b>
          </span>
          <v-icon
            color="white"
            size="20"
            @click="subMenuSideBar = false; openSideBarMenu()"
          >
            mdi-arrow-collapse-right
          </v-icon>
        </div>
        <div
          v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.services')"
          style="overflow-y: auto;"
        >
          <v-list
            dark
            class="d-flex flex-column-reverse"
          >
            <v-list-item
              v-for="(sideBarItem, index) in sideBarItemsArray"
              :key="index"
              @click="
                changeServiceItems(
                  sideBarItem.policy_id,
                  sideBarItem.service_id,
                  sideBarItem.service_type,
                  sideBarItem.translationPath,
                  sideBarItem.translationPathDescription
                )
              "
            >
              <v-list-item-title
                v-if="sideBarItem.service_type !== 'TICKET'"
                style="cursor: pointer;"
                class="text-wrap"
              >
                {{ $t(sideBarItem.translationPath) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div v-else-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.operative_support')">
          <v-list
            v-for="sideBarItem in sideBarItemsArray"
            :key="sideBarItem.service_id"
            dark
          >
            <v-list-group
              v-if="sideBarItem.service_type === 'TICKET' && canInsertRequest || sideBarItem.service_type === 'TICKET' && canViewRequestList"
              :value="false"
              color="white"
            >
              <template v-slot:activator>
                <v-list-item-title
                  class="text-wrap"
                >
                  {{ $t(sideBarItem.translationPath) }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-if="canInsertRequest"
                class="ml-2"
                @click="
                  changeServiceItems(
                    sideBarItem.policy_id,
                    sideBarItem.service_id,
                    sideBarItem.service_type,
                    sideBarItem.translationPath,
                    sideBarItem.translationPathDescription,
                    'request'
                  )
                "
              >
                <span class="sidebar-items-description-submenu">
                  {{ $t('Ticketing.request') }}
                </span>
              </v-list-item>
              <v-list-item
                v-if="canViewRequestList"
                class="ml-2"
                @click="
                  changeServiceItems(
                    sideBarItem.policy_id,
                    sideBarItem.service_id,
                    sideBarItem.service_type,
                    sideBarItem.translationPath,
                    sideBarItem.translationPathDescription,
                    'request list'
                  )
                "
              >
                <span class="sidebar-items-description-submenu">
                  {{ $t('TicketingTable.title') }}
                </span>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
        <div
          v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.settings')"
        >
          <v-list>
            <v-list-item-group
              color="white"
            >
              <v-list-item
                to="/settings"
                style="cursor: pointer"
                @click="drawer.drawerVisibility = false"
              >
                <v-list-item-title>
                  {{ $t('NavigationSideBar.registry') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/layers"
                style="cursor: pointer"
                @click="drawer.drawerVisibility = false"
              >
                <v-list-item-title>
                  {{ $t('NavigationSideBar.layer') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div
          v-if="$t(drawer.drawerTitle) == $t('NavigationDrawer.support')"
        >
          <v-list>
            <v-list-item-group
              color="white"
            >
              <v-list-item
                v-if="app_user.customerManuals.length > 0"
                to="/manuals"
                style="cursor: pointer"
                @click="openDynamicNavigationDrawer()"
              >
                <!-- @click="drawer.drawerVisibility = false" -->
                <v-list-item-title>
                  {{ $t('NavigationSideBar.manuals') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                style="cursor: pointer"
              >
                <v-list-item-title @click="downloadManual('disclaimer/Disclaimer.pdf')">
                  Disclaimer
                </v-list-item-title>
              </v-list-item>
              <span v-if="app_user.listAppUserEnabledServices.some(item => item.service_type === 'TICKET')">
                <v-list-item
                  style="cursor: pointer"
                >
                  <v-list-item-title @click="downloadManual('disclaimer/Disclaimer_Meteotrigger_2.0.pdf')">
                    Disclaimer Meteotrigger
                  </v-list-item-title>
                </v-list-item>
              </span>
              <v-list-item
                to="/video-courses"
                style="cursor: pointer"
                @click="openDynamicNavigationDrawer()"
              >
                <v-list-item-title>{{ $t('VideoCourses.title') }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                to="/contacts"
                style="cursor: pointer"
                @click="openDynamicNavigationDrawer()"
              >
                <v-list-item-title>
                  {{ $t('NavigationSideBar.contacts') }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- Menu Servizi Normali -->
    <v-bottom-sheet
      v-if="$route.params.id && !$route.path.includes('alert-ex-post')"
      v-model="sheetService"
      scrollable
    >
      <v-card>
        <v-card-title
          class="pa-3 justify-space-between"
          style="background-color: #00a644; color: white;"
        >
          {{ $t('NavigationDrawer.'+getRouteIdTranslation($route.params.id)) }}
          <v-icon
            color="white"
            @click="sheetService = false"
          >
            mdi-arrow-collapse-down
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-0">
          <services-section
            :specific-default-service="map.specificDefaultService[$route.params.id.replace('-', '')]"
            @automaticlyCloseServiceMenu="closeSheetMenu()"
          />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <!-- Menu Alert Ex-Post -->
    <v-bottom-sheet
      v-if="$route.path.includes('alert-ex-post')"
      v-model="sheetAlertExPost"
      scrollable
    >
      <v-card>
        <v-card-title
          class="pa-3 justify-space-between"
          style="background-color: #00a644; color: white;"
        >
          Alert Ex-Post
          <v-icon
            color="white"
            @click="sheetAlertExPost = false"
          >
            mdi-arrow-collapse-down
          </v-icon>
        </v-card-title>
        <v-card-text
          class="pa-0"
          style="background-color: #7ad49c;"
        >
          <alert-ex-post-section />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>


<script>
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { mapActions, mapState } from 'vuex';
import * as api from '@/api/api.js';
import { nestHierarchyItems, getFormattedDate } from '@/utils.js';
import { S3_BUCKETS } from '@/constants.js';
import AWS from 'aws-sdk';
import ServicesSection from '../core/ServicesSection.vue';
import AlertExPostSection from '../core/AlertExPostSection.vue';
import {
  svgArchive, svgRealTime, svgForecast,
  svgAdditionalServices, svgOperativeSupport,
  svgSettings, svgSupport, svgLogout
} from '@/svg.js';

export default {
  components: {
    SvgIcon,
    ServicesSection,
    AlertExPostSection
  },
  data() {
    return {
      canBeOpened: true,
      menuSideBar: false,
      subMenuSideBar: false,
      selectedItem: 0,
      sheetService: false,
      sheetAlertExPost: true,
      itemsServices: [
        {
          title: 'archive',
          label: 'Archive',
          svgPath: svgArchive
        },
        {
          title: 'real_time',
          label: 'Real-Time',
          svgPath: svgRealTime
        },
        {
          title: 'prevision',
          label: 'Forecast',
          svgPath: svgForecast
        }
      ],
      itemsMoreServices: [
        {
          title: 'operative_support',
          navigationPath: 'NavigationDrawer.operative_support',
          service_type: 'TICKET',
          svgPath: svgOperativeSupport
        },
        {
          title: 'services',
          navigationPath: 'NavigationDrawer.services',
          service_type: 'URL',
          svgPath: svgAdditionalServices
        }
      ],
      itemsSettingsAndSupport: [
        {
          title: 'support',
          navigationPath: 'NavigationDrawer.support',
          service_type: 'URL',
          svgPath: svgSupport
        },
        {
          title: 'settings',
          navigationPath: 'NavigationDrawer.settings',
          service_type: 'URL',
          svgPath: svgSettings
        }
      ],
      svgLogoutIcon: svgLogout
    };
  },
  computed: {
    ...mapState(['drawer', 'app_user', 'policy', 'map', 'ticketing']),
    routeId() {
      return this.getRouteIdTranslation(this.$route.params.id);
    },
    sideBarItemsArray() {
      const sideBarItemsArray = [];

      if (this.policy.enabledServicesPolicy.length != 0) {
        const enabledServices = ['URL', 'DOC', 'TICKET', 'ALERT_EXP', 'REPORTS', 'REPORT_HUB'];

        enabledServices.forEach(item => {
          if (item === 'TICKET') {
            this.policy.ticketingServicePermissions = this.policy.enabledServicesPolicy[item].permissions;

            if (this.policy.enabledServicesPolicy[item].enabled_service[0] != undefined) {
              this.ticketing.app_user_ticket_id = this.policy.enabledServicesPolicy[item].enabled_service[0].app_user_ticket_id;
            }

            this.policy.ticketingServicePermissions.forEach(item => {
              item.app_user_id = this.app_user.app_user_id;

              if (item.permission_name === 'Inserimento richieste') {
                this.canInsertRequest = true;
              }

              if (item.permission_name === 'Visualizza lista richieste personali') {
                this.canViewRequestList = true;
              }
            });
            this.policy.enabledServicesPolicy[item].enabled_service.map(e => {
              sideBarItemsArray.push({
                translationPath: `ServiceCard.${e.service_id}.title`,
                translationPathDescription: `ServiceCard.${e.service_id}.description`,
                app_user_id: e.app_user_id,
                service_id: e.service_id,
                policy_id: e.policy_id,
                service_type: e.service_type
              });
            });
          } else {
            this.policy.enabledServicesPolicy[item].map(e => {
              sideBarItemsArray.push({
                translationPath: `ServiceCard.${e.service_id}.title`,
                translationPathDescription: `ServiceCard.${e.service_id}.description`,
                app_user_id: e.app_user_id,
                service_id: e.service_id,
                policy_id: e.policy_id,
                service_type: e.service_type
              });
            });
          }
        });
      }

      return sideBarItemsArray;
    }
  },
  watch: {
    $route (to, from) {
      if (from.params.id) {
        this.canBeOpened = to.params.id === from.params.id;
      }
    },
    '$route.params': {
      handler(newValue) {
        if (newValue) {
          this.openDynamicNavigationDrawerWorkspace(newValue.id, `NavigationDrawer.${this.routeId}`);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['storeEnabledServicesPolicy', 'storeGlobalLayers', 'storeCustomerLayers', 'resetDrawer', 'storeAppUserPolicy', 'removeLayer']),
    async signOut() {
      await api.logOff();
      this.$router.push('/');
      location.reload();
    },
    closeSheetMenu() {
      if (this.$route.params.id !== 'Archive' && this.app_user.defaultService.GLOBAL) {
        this.sheetService = false;
      }
    },
    async openSideBarMenu() {
      this.menuSideBar = !this.menuSideBar;
    },
    async openDynamicNavigationDrawerWorkspace(title, translationPath) {
      this.overlay = true;

      if (this.$route.params.id !== title) {
        this.$gtag.event('Macroservizio', {
          event_category: title,
          event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: title
        });
        this.$router.push({
          name: 'Map',
          params: { id: title }
        });
        const data = {
          app_user_id: this.app_user.app_user_id,
          service_type: 'WS',
          ws_group: title
        };
        await this.storeEnabledServicesPolicy(data);

        if (this.policy.enabledServicesPolicy.WS.length > 0) {
          if (title && ['Archive', 'Real-Time', 'Forecast'].includes(title)) {
            this.sheetService = true;
          }
        } else {
          this.sheetService = false;
        }

        await this.storeGlobalLayers(this.policy.enabledServicesPolicy.WS.map(ws => ws.service_id));
        await this.storeCustomerLayers(this.app_user.crm_id);
        this.drawer.drawerVisibility = false;
        this.drawer.drawerTitle = translationPath;
        this.specificDefaultService(title.replace('-', ''));
      } else {
        if (title && ['Archive', 'Real-Time', 'Forecast'].includes(title) && this.canBeOpened) {
          this.sheetService = true;
        }
      }

      this.overlay = false;
    },
    specificDefaultService(scope) {
      if (this.app_user.defaultService[scope]) {
        this.map.specificDefaultService[scope] = true;
      }
    },
    async openSubMenuSideBar(title, service_type) {
      this.subMenuSideBar = true;
      this.openSideBarMenu();
      this.drawer.drawerTitle = title;
      this.drawer.workspaceSidebar = false;
      const data = {
        app_user_id: this.app_user.app_user_id,
        service_type: service_type
      };
      this.overlay = true;
      await this.storeEnabledServicesPolicy(data);
      this.overlay = false;
      this.$gtag.event('Macroservizio', {
        event_category: title.split('.')[1] === 'services' ? 'Additional services' : title.split('.')[1],
        event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        value: title.split('.')[1] === 'services' ? 'Additional services' : title.split('.')[1]
      });
    },
    async changeServiceItems(
      policy_id,
      service_id,
      service_type,
      translationPath,
      translationPathDescription,
      ticketingType
    ) {
      this.policy.translationPath = translationPath;
      this.policy.translationPathDescription = translationPathDescription;

      if (service_type !== 'ALERT EX-POST' && service_type !== 'REPORTS') {
        const params = {
          policy_id: policy_id,
          service_id: service_id,
          service_type: service_type
        };

        this.overlay = true;
        await this.storeAppUserPolicy(params);
        this.policy.appUserPolicy.forEach(policyItem => {
          if (policyItem.URL.length > 0) {
            this.policy.hierarchyItems = nestHierarchyItems(policyItem.URL);
            this.$router.push({
              name: 'Services',
              params: { id: service_id }
            });
          } else {
            if (policyItem.DOC.length > 0) {
              this.policy.hierarchyItems = nestHierarchyItems(policyItem.DOC);
              this.$router.push({
                name: 'Services',
                params: { id: service_id }
              });
            } else {
              if (policyItem.TICKET.length > 0) {
                this.ticketing.appUserPolicyTicketing = policyItem.TICKET;

                if (ticketingType === 'request') {
                  this.$router.push({
                    name: 'TicketingRequest',
                    params: { id: service_id }
                  });
                } else {
                  this.$router.push({
                    name: 'TicketingRequestList',
                    params: { id: service_id }
                  });
                }
              } else {
                if (policyItem.REPORT_HUB.length > 0) {
                  this.policy.hierarchyItems = nestHierarchyItems(policyItem.REPORT_HUB);
                  this.$router.push({
                    name: 'Report-Hub',
                    params: { id: service_id }
                  });
                }
              }
            }
          }

          this.overlay = false;
        });
      } else {
        if (service_type === 'ALERT EX-POST') {
          this.$router.push({
            name: 'Alert Ex-Post'
          });
          this.drawer.workspaceSidebar = true;
          this.drawer.disableWorkspaceSidebar = false;
          this.map.OLmap.getLayers().getArray().forEach(layer => {
            if (layer.values_.service_id !== undefined) {
              this.removeLayer(layer.values_.layer_name);
            }
          });
        }

        if (service_type === 'REPORTS') {
          this.$router.push({
            name: 'Reports'
          });
          this.drawer.workspaceSidebar = true;
          this.drawer.disableWorkspaceSidebar = false;
          this.map.OLmap.getLayers().getArray().forEach(layer => {
            if (layer.values_.service_id !== undefined) {
              this.removeLayer(layer.values_.layer_name);
            }
          });
        }
      }

      this.drawer.drawerVisibility = false;

      if (ticketingType === 'request' || ticketingType === 'request list') {
        this.$gtag.event('Sottoservizio di meteotrigger', {
          event_category: service_type,
          event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: `${this.$t(translationPath)} - ${ticketingType}`
        });
      } else {
        this.$gtag.event('Accesso servizio aggiuntivo', {
          event_category: service_type,
          event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
          value: this.$t(translationPath)
        });
      }
    },
    async downloadManual(path) {
      const params = {
        Bucket: S3_BUCKETS.application(),
        Key: path
      };
      const s3_bucket = new AWS.S3();
      const response = await s3_bucket.getSignedUrlPromise('getObject', params);

      const link = document.createElement('a');
      link.href = response;
      link.target = '_blank';
      link.click();
    },
    getRouteIdTranslation(id) {
      switch (id) {
        case 'Archive':
          return 'archive';
        case 'Forecast':
          return 'prevision';
        case 'Real-Time':
          return 'real_time';
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.menu {
  position: fixed;
  right: 12px;
  bottom: 12px;
  z-index: 3;
  border-radius:24px;
}

#subMenuSideBar {
  height: 100vh;
}

#menuSideBar {
  display: flex;
  z-index: 10;
  position: fixed;
  right: 0;
  width: 75%;
  max-width: 75%;
  height: 100vh;
  overflow-y: auto;
}

#menuSideBar > div {
  padding: 0px;
}

.divider {
  height: inherit;
}

.button.active-item {
  width: auto;
  background-color: #00822a !important;
  padding: 0px 8px;
  height: 36px;
  border-radius: 24px;
}

.containerTimeWindow {
  position: fixed;
  bottom: 12px;
  z-index: 2;
  height: auto;
  width: 100%;
}

.timeWindowButtons {
  width: auto;
  height: 48px;
  background-color: #00a644;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  padding: 0px 8px;
  margin: 0px 72px;
  align-items: center;
}

.timeWindowButtons div:nth-child(2) > .button {
  margin-left: 4px;
}

.timeWindowButtons div:nth-child(3) > .button {
  margin-left: 4px;
}

.button {
  width: 40px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.buttonText {
  opacity: 0;
  font-size: 12px;
  text-transform: capitalize;
  transition: opacity 0.3s ease;
}

.button.active-item .buttonText {
  opacity: 1;
}

.button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background-color: #3498db;
  transform: scale(0);
  transition: transform 0.3s ease;
  z-index: -1;
}

.button.active-item::before {
  transform: scale(1);
}

.topBarMenu {
  background-color: #282828;
  color: white;
  max-height: 60px;
  min-height: 60px;
  width: 100%;
  align-items: center;
  padding: 16px;
}
.v-list {
  padding: 0px!important;
}
</style>
